@import "definitions.scss";

.invoice_actions {
    .invoice_action {
        display: inline-block;
        padding-right: $pad;

        &.is-secondary .button {
            background-color: lighten($highlight_color, 30%);

            &:hover {
                background-color: $highlight_color;
            }
        }
    }
}